/* @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap'); */
/* * {
    font-family: "Inter", sans-serif;
} */


.data-font {
    font-family: "Source Code Pro", monospace;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

.flex {
    display: flex !important;
}

.aic {
    align-items: center;
}

.jcc {
    justify-content: center;
}

.fdc {
    flex-direction: column;
}

.jcsb {
    justify-content: space-between;
}

.fdr {
    flex-direction: row;
}

.view-height {
    height: 100vh;
}

.view-width {
    width: 100vw;
}

.height100 {
    height: 100%;
}

.width100 {
    width: 100%;
}

.cp {
    cursor: pointer;
}

.bb {
    box-sizing: border-box;
}

.tac {
    text-align: center;
}

.margin-center {
    margin-left: auto;
    margin-right: auto;
}

body {
    margin: 0;
}

.background-grid {
    background-image: linear-gradient(#f1f1f1 0.5px,
            transparent 0.5px,
            transparent calc(100% - 0.5px),
            #f1f1f1 calc(100% - 0.5px)),
        linear-gradient(90deg,
            #f1f1f1 0.5px,
            transparent 0.5px,
            transparent calc(100% - 0.5px),
            #f1f1f1 calc(100% - 0.5px));
    background-size: 10% 20%;
}

.mantine-Progress-bar {
    border-radius: 0 !important;
}

.text-shadow {
    background: radial-gradient(circle,
            rgba(0, 0, 0, 0.25) 0%,
            rgba(0, 0, 0, 0.5) 21%,
            rgba(0, 0, 0, 0.75) 51%,
            rgba(0, 0, 0, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: max-content;
}

/* controls */
.controls {
    position: relative;
}

/* controlsIndicator */
.controlsIndicator {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    /* Replace with your actual value for theme.radius.md */
    transition: transform 250ms ease;
    z-index: 2;
}

/* control */
.control {
    width: 100%;
    padding: 16px 24px;
    /* Replace with your actual values from theme.spacing.md and theme.spacing.lg */
    border-radius: 8px;
    /* Replace with your actual value for theme.radius.md */
    position: relative;
    height: 125px;

    @media (max-width: 600px) {
        height: 125px;
        padding: 8px 16px;
        /* Replace with your actual values from theme.spacing.xs and theme.spacing.md */
    }

    z-index: 10;
}

.control:hover {
    background-color: rgba(204, 204, 204, 0.1);
    /* Replace with your actual color values */
}

/* controlInner */
.controlInner {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
}

/* controlTitle */
.controlTitle {
    font-weight: 700;
    color: #000;
    z-index: 5;

    @media (max-width: 600px) {
        font-size: 14px;
        /* Replace with your actual value for theme.fontSizes.sm */
    }
}

/* controlDescription */
.controlDescription {
    @media (max-width: 600px) {
        font-size: 12px;
        /* Replace with your actual value for theme.fontSizes.xs */
    }
}

/* controlIcon */
.controlIcon {
    color: #fff;
    margin-right: 16px;
    /* Replace with your actual value for theme.spacing.md */
}

/* demo */
.demo {
    animation: fade 300ms ease;
    /* Replace with your actual fade animation */
}

.horizontalOverflow::-webkit-scrollbar {
    width: 12px;
    height: 8px;
    /* width of the entire scrollbar */
}

.horizontalOverflow::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.horizontalOverflow::-webkit-scrollbar-thumb {
    background-color: #ccc;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.verticalOverflow::-webkit-scrollbar {
    width: 8px;
    height: 12px;
    /* width of the entire scrollbar */
}

.verticalOverflow::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

.verticalOverflow::-webkit-scrollbar-thumb {
    background-color: #ccc;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid transparent;
    /* creates padding around scroll thumb */
}

.manual_grant_accordion_control:hover {
    background-color: transparent !important;
}

.mantine-Input-input:focus {
    outline: 2px solid var(--mantine-color-blue-3) !important;
    border-color: var(--mantine-color-blue-6) !important;
    outline-offset: 0px;
    outline-width: 2px !important;
}

.supportedPlatformsOffset {
    /* animation-name: supportedPlatformLogoOffset; */
    animation-timing-function: linear;
    /* Refers to the name of your @keyframes element below */
    animation-duration: 10s;
    animation-delay: 5s;
    /* Change to speed up or slow down */
    animation-iteration-count: infinite;
}

.supportedPlatforms {
    animation-timing-function: linear;
    /* animation-name: supportedPlatformLogo; */
    /* Refers to the name of your @keyframes element below */
    animation-duration: 10s;
    /* Change to speed up or slow down */
    animation-iteration-count: infinite;

}


.supportedPlatformsOffset {
    animation-name: supportedPlatformLogoOffset !important;
}

.supportedPlatforms {
    animation-name: supportedPlatformLogo !important;
}

/* @media (min-width: 600px) {
    .supportedPlatformsOffset {
        animation-name: supportedPlatformLogoOffsetSmall !important;
    }

    .supportedPlatforms {
        animation-name: supportedPlatformLogoSmall !important;
    }
}

@media (max-width: 600px) {
    
} */

.hyperlink {
    color: var(--mantine-color-blue-6) !important;
}

[data-ui="NextStudioLayout"] {
    height: calc(100vh - 80px) !important;
}

@keyframes supportedPlatformLogoSmall {
    0% {
        transform: translateX(-300px);
    }

    100% {
        transform: translateX(300px);
    }
}

@keyframes supportedPlatformLogoOffsetSmall {
    0% {
        transform: translateX(-300px);
    }

    100% {
        transform: translateX(300px);
    }
}

@keyframes supportedPlatformLogo {
    0% {
        transform: translateX(-500px);
    }

    100% {
        transform: translateX(500px);
    }
}

@keyframes supportedPlatformLogoOffset {
    0% {
        transform: translateX(-500px);
    }

    100% {
        transform: translateX(500px);
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 960px) {
    .demo {
        padding-left: 0;
    }
}

/* HEADER */
.link {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 16px;
    /* Replace with your actual value for theme.spacing.md */
    padding-right: 16px;
    /* Replace with your actual value for theme.spacing.md */
    text-decoration: none;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    padding: 7px 16px;
    /* Replace with your actual value for theme.fontSizes.sm */

    @media (max-width: 600px) {
        height: 42px;
        /* Replace with your actual value for rem(42) */
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
    }
}

canvas#canvas {
    /* z-index: -1; */
    position: absolute;
    width: 100vw;
    height: 100%;
    transform: rotate(0deg) scale(1);
    --gradient-color-1: #488efe;
    --gradient-color-2: #000;
    --gradient-color-3: #488efe;
    --gradient-color-4: #000;
    --gradient-speed: 0.000006;
    z-index: 200;
}

.link:hover {
    background-color: #f9f9f9;
    /* Replace with your actual hover background color */
}

/* subLink */
.subLink {
    width: 100%;
    padding: 8px 16px;
    /* Replace with your actual values for theme.spacing.xs and theme.spacing.md */
    border-radius: 8px;
    /* Replace with your actual value for theme.radius.md */
}

.subLink:hover {
    background-color: #fafafa;
    /* Replace with your actual hover background color */
}

/* dropdownFooter */
.dropdownFooter {
    background-color: #fafafa;
    /* Replace with your actual background color for dark or gray scheme */
    margin: -16px;
    /* Replace with your actual value for theme.spacing.md */
    margin-top: 8px;
    /* Replace with your actual value for theme.spacing.sm */
    padding: 16px 32px;
    /* Replace with your actual values for theme.spacing.md and theme.spacing.xl */
    border-top: 1px solid #ccc;
    /* Replace with your actual border color based on the color scheme */
}

.creating-agency-loader::after {
    border-width: 5px !important;
}

.notificationUserSelector {
    transition: all .3s;
}

.notificationUserSelector:active {
    transform: scale(0.95);
}

.wave {
    animation-name: wave-animation;
    /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;
    /* Change to speed up or slow down */
    animation-iteration-count: infinite;
    /* Never stop waving :) */
    transform-origin: 70% 70%;
    /* Pivot around the bottom-left palm */
    display: inline-block;
}

/* .shakeContainer .shake {
    padding: 20px;
    border-radius: 40px;
} */
.shake {
    animation-delay: -.05s;
    animation-duration: .4s
}

.shakeContainer .shake:nth-child(2n) {
    animation-name: shake1;
    animation-iteration-count: infinite;
    transform-origin: 50% 10%;
}

.shakeContainer .shake:nth-child(2n-1) {
    animation-name: shake2;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transform-origin: 30% 5%;
}

.focus-bounce {
    transition: all .2s;
}

.focus-bounce:active {
    transform: scale(.93);
}

.borderGlow {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.borderGlow .inner {
    position: relative;
    z-index: 1;
    width: 100%;
    border-radius: 15px;
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

.borderGlow .inner {
    margin: 4px;
}

.borderGlow::before {
    content: "";
    display: block;
    background: radial-gradient(circle,
            var(--mantine-color-blue-9) 0%,
            var(--mantine-color-blue-1) 50%,
            var(--mantine-color-blue-9) 100%);
    height: 140px;
    width: 300px;
    transform: translate(0);
    position: absolute;
    animation: rotate 5s linear forwards infinite;
    z-index: 0;
    top: 50%;
    transform-origin: top center;
}

.demoIntakeFormQuestionInput input {
    position: relative !important;
    z-index: 100 !important;
}

.demoIntakeFormQuestionInput input:focus {
    position: relative !important;
    z-index: 200 !important;
}





#root_invite_continer {
    position: relative;
}

#root_invite_content {
    transition: all .3s;
    width: 100%;
}

.dimTopBorder {
    border-top-color: rgba(0, 0, 0, .1) !important;
    transition: all .3s;
    max-height: 600px !important;
    min-height: 600px !important;
    justify-content: flex-start !important;
}

.rootInviteContentSetback {
    opacity: .5;
    transform: scale(.98);
}

@keyframes shake1 {
    0% {
        transform: rotate(-.5deg);
        animation-timing-function: ease-in;
    }

    50% {
        transform: rotate(.75deg);
        animation-timing-function: ease-out;
    }
}

@keyframes shake2 {
    0% {
        transform: rotate(.5deg);
        animation-timing-function: ease-in;
    }

    50% {
        transform: rotate(-.75deg);
        animation-timing-function: ease-out;
    }
}

@keyframes wave-animation {
    0% {
        transform: rotate(0.0deg)
    }

    10% {
        transform: rotate(14.0deg)
    }

    /* The following five values can be played with to make the waving more or less extreme */
    20% {
        transform: rotate(-8.0deg)
    }

    30% {
        transform: rotate(14.0deg)
    }

    40% {
        transform: rotate(-4.0deg)
    }

    50% {
        transform: rotate(10.0deg)
    }

    60% {
        transform: rotate(0.0deg)
    }

    /* Reset for the last half to pause */
    100% {
        transform: rotate(0.0deg)
    }
}

/* hiddenMobile */
@media (max-width: 960px) {
    .hiddenMobile {
        display: none;
    }
}

/* hiddenDesktop */
@media (min-width: 960px) {
    .hiddenDesktop {
        display: none;
    }
}

.mantine-Input-input:focus {
    outline: 2px solid var(--mantine-color-blue-3) !important;
    border-color: var(--mantine-color-blue-6) !important;
    outline-offset: 0px;
    outline-width: 2px !important;
}

.mantine-PasswordInput-innerInput:focus {
    outline: 2px solid var(--mantine-color-blue-3) !important;
    border-color: var(--mantine-color-blue-6) !important;
    outline-offset: 0px;
    outline-width: 2px !important;
}

.mantine-MultiSelect-input[data-expanded="true"] {
    outline: 2px solid var(--mantine-color-blue-3) !important;
    border-color: var(--mantine-color-blue-6) !important;
    outline-offset: 0px;
    outline-width: 2px !important;
}

.senja-reviews {
    /* height: 40px;
    max-height: 40px;
    min-height: 40px;
    width: fit-content; */
}

.blueBulletList li::marker {
    color: var(--mantine-color-blue-6);
}